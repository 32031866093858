const addressToZone: {
  [key: string]: String
} = {
  "19-mars-62": "112",
  "2 croix": "112",
  "2 Ormes": "114",
  "3 Paroisses (chemin)": "114",
  "Abel Ruel": "114",
  Académie: "115",
  "Adrien Recouvreur": "114",
  Aileries: "114",
  "Albéric Dubois": "115",
  "Albert Blanchoin": "114",
  "Albert Schweitzer": "112",
  "Albert Thomas": "113",
  "Alexandra David-Neel": "113",
  "Alexandre 1er": "112",
  "Alexis Axilette": "114",
  "Alexis Guillier": "112",
  "Anatole France": "112",
  "Anciennes provinces ": "114",
  "André Gardot": "115",
  "André Gautier (sq)": "114",
  "André Leroy": "113",
  "André Maurois": "114",
  "Angélique de Coudray (sq)": "113",
  Anjou: "115",
  "Anne Franck": "113",
  "Annie Girardot": "113",
  Antioche: "112",
  Appentis: "114",
  "Aqueduc Romain": "114",
  "Arborétum (sq)": "113",
  "Arborétum (rés)": "114",
  Ardrières: "112",
  "Aristide justeau": "112",
  Arno: "113",
  Artois: "112",
  "Asile Saint Joseph": "115",
  "Aspirant Felix Pineau": "112",
  Assas: "113",
  Auber: "112",
  "Aubry Frères": "114",
  Audusson: "115",
  "Auguste Allonneau": "112",
  "Auguste Blandeau": "114",
  "Auguste Fonteneau": "113",
  "Auguste Gautier": "115",
  "Auteuil (chemin)": "113",
  Baladins: "114",
  Ballée: "113",
  Bamako: "114",
  Banchais: "112",
  Baraterie: "113",
  "Barbara Stirling": "113",
  "Bas des Eclateries": "113",
  "Bas des Plaines": "113",
  Baugas: "112",
  Baumette: "114",
  "Beauregard (pas)": "112",
  Beauséjour: "113",
  Beauval: "114",
  Bédier: "114",
  Béjonnière: "114",
  "Bel Air": "115",
  Belfort: "112",
  Belgique: "115",
  "Belle Borne": "112",
  "Belle fontaine": "115",
  Béranger: "112",
  Bernier: "113",
  Biardeau: "113",
  "Blaise Pascal": "113",
  Blancheraie: "115",
  Bocage: "114",
  Boieldieu: "112",
  "Bois Brault (chemin)": "114",
  "Bon Repos": "114",
  Bonnelles: "114",
  "Bouche Thomas": "114",
  "Bouchemaine (rte)": "114",
  Bougère: "115",
  "Bourg la Croix ": "113",
  Bourgonnier: "113",
  Bouteillerie: "112",
  Brisepotière: "112",
  Brissac: "115",
  Brosseau: "115",
  "Bruants ": "114",
  Bruxelles: "114",
  Buis: "114",
  "Buttes de Pigeon": "115",
  Caléïdes: "114",
  "Cambridge (rés.)": "113",
  Camélias: "112",
  "Camille Claudel": "112",
  "Camille Leduc": "113",
  "Carl Linné": "114",
  "Célestin Port": "115",
  "César Geoffray": "115",
  Chaffauds: "114",
  Chalouère: "112",
  "Champ de l'Aire": "114",
  "Champs de Saint-Martin": "114",
  "Champs Verts": "114",
  "Chanoine Colonel Panaget": "115",
  "Chanoine Corillon": "113",
  "Chanoine Jean Brac": "112",
  Chantilly: "113",
  Chantoiseau: "114",
  Chanzy: "115",
  Chapelle: "113",
  Chaptal: "112",
  "Charles Baudelaire": "114",
  "Charles Berjole": "112",
  "Charles Denis": "113",
  "Charles D'Etriché": "113",
  "Charles Gounod": "112",
  "Charles Péguy": "113",
  "Charles Syndic": "114",
  "Charlotte Delbo": "115",
  Charnacé: "113",
  Charpentière: "114",
  "Château Orgemont": "114",
  Chateaubriand: "113",
  "Chêne Vert (imp.)": "112",
  Chèvre: "113",
  Chèvrefeuille: "114",
  "Chevrollier (sq)": "114",
  "Claude Bernard": "113",
  "Claude de Bussy": "113",
  "Claude François Sorinière": "114",
  "Clément Ader": "112",
  "Clément Janequin": "114",
  "Clément Pasquereau": "112",
  Clon: "115",
  "Clouet des Pesruches": "114",
  Colbert: "115",
  "Colette Besson": "113",
  Colombier: "113",
  "Commandant Bory": "112",
  "Commandant Cousteau (sq)": "114",
  "Comte de Tourville": "112",
  Condorcet: "115",
  "Constant Lemoine": "115",
  Contades: "115",
  "Cormier (chem)": "113",
  Corne: "114",
  Corot: "113",
  "Costes et Bellonte": "113",
  Couperin: "113",
  Cubain: "113",
  "Daguenet (cité-rue)": "112",
  Daillière: "113",
  Dainville: "113",
  Dalhias: "112",
  "Daniel Gélin": "113",
  Danjoutin: "114",
  "Denis Papin": "115",
  "Denise Duperray": "113",
  Desbois: "112",
  Desjardins: "115",
  Desmazières: "113",
  Desportes: "115",
  Devansaye: "113",
  Diderot: "115",
  Douma: "115",
  "Dr Guichard >95 et >96": "114",
  "Dr Guichard <95 et <96": "115",
  "Dr M et J Canonne": "115",
  "Dr Zamenhof": "114",
  "Dupetit Thouars": "115",
  Dubois: "112",
  "Dulcie September (allée)": "112",
  "Dumont Durville": "114",
  "Eblé <100 et <119": "115",
  "Eblé >100 et >119": "114",
  "Ecce Homo": "115",
  "Edgar Desgas": "113",
  "Edgar Quinet": "113",
  Edison: "113",
  "Edith Piaf": "113",
  "Edouard André": "113",
  "Edouard Branly": "112",
  "Edouard Guinel": "113",
  "Edouard Manet": "113",
  "Edouard René Coeffard": "112",
  "Edouard Vaillant": "114",
  "Elisée Reclus": "112",
  "Emile Blavier": "112",
  "Emile Joulain": "113",
  "Emile Zola": "112",
  "Emilie Haffner": "112",
  "Emmanuel Camus": "113",
  "Emmanuel Triquier": "113",
  Enfer: "114",
  Epluchard: "114",
  "Ernest Mottay": "113",
  "Esnault Dufresne": "112",
  "Estienne d'Orves": "113",
  Esvière: "115",
  "Etienne Martin": "112",
  "Eugène Chaumin": "114",
  "Eugène Claudius Petit": "112",
  "Eugène Delacroix": "113",
  "Eugène Roinet": "115",
  "Eugénie Poilane": "115",
  Evain: "115",
  Faidherbe: "115",
  Farfadets: "112",
  "Félix Faure": "112",
  "Felix Lorioux": "114",
  Fénelon: "113",
  "Fernand Forest": "113",
  "Feux Follets": "112",
  Flandre: "112",
  Fleurs: "112",
  "Florent Cornilleau": "112",
  "Forces Françaises Libres (allée)": "112",
  "Fort de Vaux": "112",
  Foucaudière: "113",
  "Fours à chaux": "112",
  "Frais Foyers": "114",
  "Francis Meilland": "114",
  "François Besnard": "115",
  "François Cevert": "114",
  "François Guilbault (sq)": "113",
  "François Mauriac ": "114",
  "François Simon": "114",
  "François Truffaut": "114",
  "Françoise Dolto (sq)": "113",
  Franklin: "115",
  "Frédéric Chatelus": "114",
  "Frémur <73 et <78": "115",
  "Frémur >73 et >78": "114",
  Fresne: "113",
  Fulton: "115",
  "Gabriel Julien Alix": "114",
  "Gabriel Lecombre": "113",
  "Gaby Morlay ": "113",
  Galilée: "113",
  Gare: "115",
  Gargarine: "114",
  "Gaston Allard ": "114",
  "Gaston Ramon (que 16)": "112",
  "Gate argent": "112",
  "Général Bizot": "115",
  "General Foy": "112",
  "General Lamoriciere": "112",
  Genêts: "114",
  Genvrie: "114",
  "Georges Bernanos": "113",
  "Georges Bizet": "114",
  "Georges Chesneau": "113",
  "Georges Crousil": "113",
  "Georges Guynemer": "114",
  Géricault: "113",
  "Giffard Langevin": "115",
  "Ginette Leroux": "113",
  "Givres (imp)": "114",
  Glycines: "114",
  "Grand Douzille": "114",
  "Grand Maillet": "114",
  "Grand Montréjeau": "113",
  "Grandes Maulévries": "113",
  Guérin: "112",
  "Guillaume Lekeu": "112",
  "Gustave Bayol": "114",
  "Gustave Mareau": "115",
  Gutenberg: "115",
  "Halopé Frères": "113",
  "Haut des Eclateries": "113",
  "Haut Pressoir": "113",
  "Haute de Beaumanoir": "113",
  Hédouville: "112",
  "Henri Bergson": "114",
  "Henri Bouriche": "113",
  "Henri Dutilleux (esplan.)": "115",
  "Henri Jaham Desrivaux": "113",
  "Henriette Bicard": "112",
  "Henry Gréville": "115",
  "Hermitage (imp.)": "113",
  "Hermitage (rte)": "114",
  Heurtevents: "112",
  "Hippolyte Maindron": "115",
  Hirondelle: "114",
  Hoche: "115",
  "Hubert Grimault": "115",
  "Hutreau (chemin)": "114",
  Iéna: "115",
  "Ile de France": "112",
  Inkermann: "113",
  Isoret: "112",
  "Jacques Bordier": "115",
  "Jacques Cartier": "112",
  "Jacques Granneau": "115",
  "Jacques Ibert": "113",
  "Jacques Millot": "113",
  "Jacques Portet ": "114",
  "Jan Pallach": "114",
  "Jeammapes ": "114",
  "Jean Arp": "113",
  "Jean Bedouet": "113",
  "Jean Bodin": "115",
  "Jean Commere": "113",
  "Jean d'Alembert": "113",
  "Jean de la Fontaine": "115",
  "Jean Guignard": "115",
  "Jean Jaurès": "113",
  "Jean Joxé (<14)": "112",
  "Jean Macé": "112",
  "Jean Marie Raimbault": "112",
  "Jean Mermoz": "114",
  "Jean Orhon": "112",
  "Jean Perrin": "115",
  "Jean XXIII": "114",
  "Jean-Adrien Mercier": "113",
  "Jean-Emile Molland": "113",
  "Jeanne d'Arc": "115",
  "Jeanne Jugan": "112",
  Jemmapes: "114",
  Jerusalem: "112",
  "Joachim du Bellay": "115",
  "Jonchères (square)": "114",
  "Joseph Bédier": "114",
  "Joseph Cussonneau": "112",
  "Jules Dauban": "115",
  "Jules Verne": "114",
  "Julien Gracq": "115",
  "Justices (place)": "113",
  Kellerman: "115",
  Kléber: "115",
  Korrigans: "112",
  Kruger: "112",
  "La Boëtie": "113",
  "Laboureau (imp)": "112",
  Laennec: "113",
  Lafayette: "115",
  "Lafontaine (pas.)": "115",
  "Lainé Laroche": "113",
  Lamartine: "112",
  Landemaure: "114",
  "Lardin de Musset": "112",
  Laréveillère: "112",
  "Lattre de Tassigny": "114",
  Laurier: "114",
  Lavande: "114",
  "Le parc fleuri (rés.)": "115",
  "Le Ponant (rés)": "114",
  Lebas: "113",
  Lebasque: "113",
  "Leclerc Guillory": "115",
  Legouz: "112",
  LeLoyer: "113",
  "Léo Delibes (sq)": "113",
  "Léo Lagrange": "113",
  "Léon Blum": "114",
  "Leon Jagot": "112",
  "Léon Pavot": "115",
  "Léon Tulot": "112",
  "Leopold Sédar (allée)": "112",
  "Létanduère <151 et <168": "115",
  "Létanduère >151 et >168": "114",
  Levavasseur: "112",
  Leverrier: "112",
  "Liberté (bd)": "113",
  "Lierre ": "114",
  Lilas: "113",
  "Lino Ventura": "113",
  Liseron: "114",
  Littré: "113",
  Locarno: "115",
  Longchamps: "113",
  "Longs Boyaux": "113",
  "Lorette (pas.)": "114",
  "Louis Blanc": "112",
  "Louis et Maurice Frouin": "114",
  "Louis Gain": "115",
  "Louis Germain": "113",
  "Louis Jouvet (sq)": "113",
  "Louis Legendre": "115",
  "Louis Leroy": "112",
  "Lucien Bejeau": "112",
  Lutin: "112",
  Lycée: "115",
  Mabillière: "113",
  Madeleine: "113",
  "Madeleine Allais": "113",
  "Maitre-Ecole": "113",
  "Major Allard": "112",
  Marbode: "113",
  "Marc de Turbilly": "114",
  "Marc Leclerc": "115",
  "Marc Sangnier": "114",
  Marceau: "115",
  "Marcel Chuteaux": "112",
  "Marcel Guénault": "112",
  "Marcel Pagnol": "114",
  "Marcel Pajotin": "113",
  "Marcelin Berthelot": "114",
  Marcheteau: "112",
  "Maréchal Juin": "114",
  "Marguerite Legros": "112",
  "Marguerite Yourcenar": "113",
  "Marianne (bd)": "113",
  "Martin Luther King": "114",
  Massenet: "112",
  "Mathilde Alanic": "115",
  "Maurice Blanchard": "115",
  "Maurice Jénot": "112",
  "Maurice Langlet": "114",
  "Maurice Pasquier": "112",
  "Maurice Pouzet": "113",
  "Maurice Ravel": "113",
  "Maurice Tardat": "114",
  "Max d'Olonne": "113",
  "Max Richard": "115",
  "Merlet De Laboulaye": "113",
  Messine: "113",
  "Mgr Rumeau": "115",
  "Michel Fourcade": "114",
  "Michel Passin": "113",
  Michelet: "113",
  Mimosas: "112",
  Mirabeau: "115",
  "Mithraeum (allée)": "115",
  "Modigliani (square)": "113",
  Moirin: "115",
  "Mongazon (sq)": "113",
  "Mont Cassin": "114",
  "Montaigne (sauf géant)": "115",
  Morellerie: "114",
  Mortiers: "113",
  "Mulot (passage)": "115",
  "Musses (chemin)": "114",
  "Nicolas Bataille": "114",
  "Noël Pinot": "114",
  "Noisetiers (chemin)": "113",
  Noyers: "113",
  Oisonnières: "114",
  "Olivier Couffon": "115",
  "Olivier Giran": "115",
  Orgemont: "114",
  "Pablo Picasso": "113",
  Panneton: "113",
  "Papiau Verrie": "114",
  Parmentier: "113",
  "Pascal (passage)": "113",
  "Pasteur (<60)": "115",
  "Pasteur (>60)": "112",
  "Paul Cézanne": "113",
  "Paul Claudel": "114",
  "Paul Eluard": "113",
  "Paul Henry": "112",
  "Paul Langevin": "115",
  "Paul Pousset": "112",
  "Paul Verlaine": "113",
  "Petit Anjou": "115",
  "Petit Arceau": "112",
  "Petit Damiette": "114",
  "Petit Montrejeau": "113",
  "Petit Muletier": "113",
  "Petit Papillaie": "112",
  "Petit Prince": "115",
  "Petit-Château": "113",
  "Petites Maulévries": "114",
  "Petites Musses": "114",
  "Philippe la Peyrouse": "114",
  Picardie: "112",
  "Pied Moisi": "114",
  Pierre: "114",
  "Pierre Brossolette ": "114",
  "Pierre Curie": "114",
  "Pierre de Coubertin": "113",
  "Pierre Lise": "115",
  "Pierre Semard": "115",
  "Pigeon Fleuri": "112",
  Pignerolles: "115",
  Pin: "115",
  Pinterie: "112",
  Pise: "113",
  "Ponts de Cé": "113",
  "Pré pigeon (>14)": "112",
  Prébaudelle: "115",
  "Préaubert ": "114",
  Préfecture: "115",
  "Prévoyants de l'Avenir": "114",
  Prieuré: "113",
  "Prosper Bigeard": "112",
  Proust: "115",
  "Puccini (square)": "113",
  "Puits Anceau": "113",
  "Puy Garnier": "112",
  Pyramide: "113",
  "Quatre Barbes": "115",
  Quinconce: "115",
  Rabelais: "115",
  Racine: "115",
  Raimu: "113",
  Rame: "115",
  Ravenel: "115",
  Réaumur: "112",
  "Relais de la poste": "112",
  Renan: "112",
  "René Brémont": "115",
  "René Guilleux": "113",
  "René Philippe": "114",
  "René Rabault": "112",
  "Richard Duvernay": "112",
  "Richard Lenoir": "112",
  Rivoli: "113",
  "Robert d'Arbrissel": "114",
  "Robert Déan": "112",
  "Robert Surcouf": "112",
  "Rocher (cour)": "112",
  "Rochetière (pas. et cité)": "115",
  "Roger Groizleau": "115",
  "Roger Salengro": "114",
  "Roi de Pologne": "115",
  "Roland Dorgeles": "112",
  "Roland Garros": "114",
  Romarin: "114",
  "Romy Schneider ": "113",
  Ronsard: "112",
  "Rosa Parks": "112",
  Roseraies: "113",
  "Rouget de l'isle": "112",
  Rousseau: "115",
  Roussillon: "112",
  "Ruisseau (sq)": "113",
  "Saint Augustin": "113",
  "Saint Charles": "112",
  Salpinte: "114",
  "Saret Terrasse": "115",
  "Sarment (sq)": "113",
  Saumuroise: "113",
  Savary: "115",
  "Scala (allée)": "113",
  "Sergent Bobillot": "115",
  "Serpentine (allée)": "114",
  "Sidney Béchet": "113",
  "Simone Signoret": "113",
  "Simone Weil": "114",
  "Sonia Delaunay": "114",
  "Souché de Vigne": "113",
  "Stéphane Hessel (allée)": "112",
  "St-Eutrope": "115",
  "St-Exupéry": "115",
  "St-Laud (cour)": "115",
  "St-Léonard": "113",
  "St-Michel": "115",
  Strasbourg: "114",
  Suffren: "115",
  Talot: "115",
  Tarin: "115",
  Temple: "115",
  Templerie: "113",
  "Terre Noire": "115",
  "Terrien Cocherel": "112",
  Toscane: "113",
  Toucheronde: "113",
  "Tour Landry": "114",
  Tournebelles: "114",
  Toussenel: "113",
  Traversière: "112",
  Treille: "113",
  Troenes: "114",
  "Trois Moulins": "113",
  "Trois Roses (rés)": "114",
  "Trousselière (sq)": "113",
  Tunis: "113",
  "Turpin de Crissé": "115",
  "Val de Loire": "113",
  "Vauban <58 et <39": "114",
  "Vauban >58 et >39": "115",
  Vaucanson: "112",
  Vaugareau: "112",
  Vendanges: "113",
  Verdi: "113",
  "Vergers (sq)": "113",
  "Victor Hugo (sauf 39)": "112",
  "Victor Jara": "114",
  "Vieilles Carrières": "113",
  Village: "114",
  "Village d'Anjou (place)": "114",
  "Villebois Mareuil": "112",
  Villechien: "113",
  Villesicard: "113",
  Villoutreys: "113",
  "Vincennes (sq)": "113",
  "Viola Farber": "112",
  Viviers: "113",
  Volney: "113",
  "Volney (n°7)": "115",
  Votier: "115",
  "Waldeck Rousseau": "115",
  "Winston Churchill": "114",
  "Yves Montand": "113",
  "Yvonne Poirel": "115",
}

export default addressToZone