import React from 'react';
import './App.css';
import useSpeechToText, { ResultType } from 'react-hook-speech-to-text';
import addressToZone from './address-to-zone';
import micIcon from './mic.svg';

function App() {
  const {
    error,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false
  });
  
  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;

  return (
    <div className="app">
      <button onClick={isRecording ? stopSpeechToText : startSpeechToText}>
        <span>{isRecording ? 'Arrêter de chercher une zone de livraison' : 'Chercher une zone de livraison'}</span>
        <img data-recording={isRecording} src={micIcon} alt="" />
      </button>
      {results && results.length > 0 && <Result result={results.pop() as ResultType} />}
    </div>
  );
}

function Result({ result }: { result: ResultType }) {
  const addresses = Object.keys(addressToZone).filter(address => {
    return address.toLowerCase().indexOf(result.transcript.toLowerCase().trim()) !== -1
  })

  return <>
    <ul>
      <li><strong>{result.transcript}</strong></li>
      {addresses.map((address, index) => (
        <li key={`zone-${index}`}><div>{address}</div><div>{addressToZone[address]}</div></li>
      ))}
    </ul>
  </>
}

export default App;
